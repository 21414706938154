import React from "react"
import PropTypes from "prop-types"
import Paragraph from "@elements/paragraph"
import Heading from "@elements/heading"
import Anchor from "@elements/anchor"
import List from "@elements/list"
import Grid from "@elements/grid"
import parse, { domToReact } from "html-react-parser"
import Flex from "@elements/flex"

// TODO: Make this go away, just revert to regular HTML component
// This is an old failed experiment and makes the legal pages 'pop in'

const options = {
  htmlparser2: {
    lowerCaseTags: false,
  },
  replace: ({ name, attribs, children }) => {
    if (!attribs) {
      return
    }

    const toCamel = str => {
      return str.replace(/([-_][a-z])/gi, subStr => {
        return subStr.toUpperCase().replace("-", "").replace("_", "")
      })
    }

    const isArray = function (object) {
      return Array.isArray(object)
    }

    const isObject = function (object) {
      return (
        object === Object(object) &&
        !isArray(object) &&
        typeof object !== "function"
      )
    }

    const keysToCamel = function (object) {
      if (isObject(object)) {
        const children = {}

        Object.keys(object).forEach(child => {
          children[toCamel(child)] = keysToCamel(object[child])
        })

        return children
      } else if (isArray(object)) {
        return object.map(i => {
          return keysToCamel(i)
        })
      }

      return object
    }

    const camelAttribs = keysToCamel(attribs)
    switch (name) {
      case "list":
        return <List {...attribs}>{domToReact(children, options)}</List>
      case "flex":
        return <Flex {...attribs}>{domToReact(children, options)}</Flex>
      case "paragraph":
        return (
          <Paragraph {...attribs}>{domToReact(children, options)}</Paragraph>
        )
      case "heading":
        return <Heading {...attribs}>{domToReact(children, options)}</Heading>
      case "anchor":
        return <Anchor {...attribs}>{domToReact(children, options)}</Anchor>
      case "grid":
        return <Grid {...camelAttribs}>{domToReact(children, options)}</Grid>
    }
  },
}

const ReactHtml = ({ html }) => {
  return parse(html, options)
}

ReactHtml.propTypes = {
  html: PropTypes.string.isRequired,
}

export default ReactHtml
